import api from '@/api/API'

export const login = (username, password) => {
  return api({
    method: 'POST',
    url: '/api/app/account/login',
    data: {
      userName: username,
      password: password,
    },
  }).then((data) => data)
}

export const thirdPartyLogin = (params) => {
  return api({
    method: 'POST',
    url: '/api/app/account/login-third-party',
    data: params,
  }).then((data) => data)
}

export const getPartyLoginParam = (isZhiWeiYun = false) => {
  return api({
    method: 'GET',
    url: `/api/app/app-profile/login-third-party?isZhiWeiYun=${isZhiWeiYun}`,
  }).then((data) => data)
}

export const verifyLogin = (phone, checkCode) => {
  return api({
    method: 'POST',
    url: '/api/app/account/verify-login',
    data: {
      phone: phone,
      checkCode: checkCode,
    },
  }).then((data) => data)
}

export const PhoneCheck = (wxPhoneCheck) => {
  return api({
    method: 'GET',
    url: '/api/app/account/phone-check?phone=' + wxPhoneCheck.phone + '&checkCode=' + wxPhoneCheck.checkCode,
    data: {
      phone: wxPhoneCheck.phone,
      checkCode: wxPhoneCheck.checkCode,
    },
  }).then((data) => data)
}

export const Pclogin = (pageAppOpenId, unionId) => {
  return api({
    method: 'POST',
    url: '/api/app/account/pc-login',
    data: {
      pageAppOpenId: pageAppOpenId,
      unionId: unionId,
    },
  }).then((data) => data)
}

export const wxUserInfo = (code) => {
  return api({
    method: 'GET',
    url: '/api/app/pub-method/get-wx-login-info?code=' + code,
    data: {
      code: code,
    },
  }).then((data) => data)
}

export const getSmsCaptcha = ({ mobile }) => {
  return new Promise(() => { }).then(data => data)
}

export const logout = () =>
  api({
    method: 'GET',
    url: '/api/account/logout',
  }).then(({ data }) => data)

export const getTenant = tenantName =>
  api({
    method: 'GET',
    url: `/api/abp/multi-tenancy/tenants/by-name/${tenantName}`,
  }).then(({ data }) => data)

export const getTenantById = tenantId =>
  api({
    method: 'GET',
    url: `/api/abp/multi-tenancy/tenants/by-id/${tenantId}`,
  }).then(({ data }) => data)

export const sendVerifycationCode = (phone) =>
  api({
    method: 'Post',
    url: '/api/app/account/send-verifycation-code',
    data: {
      phone,
    },
  }).then(({ data }) => data)
